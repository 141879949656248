import React, { useEffect, useState } from "react";
import { ButtonGroup, Link, IconButton, Text, Image, Center, Box, Flex, Button, Tooltip } from "@chakra-ui/react";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { ApiUserService, ICurrentToS } from "../../services/http";
import { useAuth } from "../../provider/authentication";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { IS_PRODUCTION } from "../../constants";
import { ApiSocialAuthentication } from "../../services/http/apiSocialAuthentication";

interface IFooterProps {
  isMarketplace: boolean;
}

export default function Footer({ isMarketplace }: IFooterProps) {
  const { isAuthenticated, user } = useAuth();
  const [currentToSObject, setCurrentToSObject] = useState<ICurrentToS>();

  const isSocialUser =
    user &&
    (user.isSocialUser || user?.firstOrganisation?.organisationType == 4 || user?.firstOrganisation?.organisationType == 5);

  const openDocument = () => {
    window.open(currentToSObject?.tosFile, "_blank");
  };
  useEffect(() => {
    if (isAuthenticated && !currentToSObject) {
      ApiUserService.getMyToS().then(res => {
        if (res.status === 200) {
          setCurrentToSObject(res.data);
        }
      });
    }
  }, [isAuthenticated, currentToSObject]);

  /**
   * Handles the social login with Edupool.
   *
   */
  function handleLoginWithBildungsmediathekNRW() {
    const callbackUrl = IS_PRODUCTION
      ? "https://portal.vil.digital/login/social/oidc/callback"
      : "http://localhost:3000/login/social/oidc/callback";
    ApiSocialAuthentication.redirectToProvider("edupool", callbackUrl);
  }

  return (
    <Box bg={isMarketplace ? "white" : "darkBlue.500"} w={"100%"} py={8}>
      <Box color={isMarketplace ? "darkBlue.500" : "white"} fontSize={"md"}>
        <Flex gap={{ base: "4", md: "5" }} justifyContent="center" flexDir={"column"}>
          <Flex justifyContent="center" align={"center"} gap={4} flexDir={{ base: "column", lg: "row" }}>
            <Image
              fontFamily={"Assistant"}
              src={isMarketplace ? "/img/vil-logo.png" : "/img/VIL_Logo_negativ_Wortmarke.png"}
              alt={"logo"}
              height={"40px"}
            />
            <Link fontWeight={600} href={"/datenschutz"}>
              Datenschutz
            </Link>
            <Link
              fontWeight={600}
              onClick={() => {
                openDocument();
              }}
            >
              Nutzungsbedingungen
            </Link>
            <Link fontWeight={600} href={"/impressum"}>
              Impressum
            </Link>{" "}
            {isSocialUser && (
              <Tooltip label="Marktplatz">
                <Button
                  as={"a"}
                  href={"https://www.vil.digital/marktplatz"}
                  variant="outline"
                  colorScheme={"white"}
                  _hover={{ bgColor: "darkBlue.600" }}
                  target="_blank"
                  size={{ base: "sm", md: "md", lg: "md" }}
                  leftIcon={<ExternalLinkIcon className="w-5 h-5" />}
                >
                  Weitere Inhalte (kostenpflichtig)
                </Button>
              </Tooltip>
            )}
            <ButtonGroup variant={"ghost"}>
              <IconButton
                as={"a"}
                color={isMarketplace ? "darkBlue.500" : "white"}
                href={"https://www.linkedin.com/company/vil-gmbh/"}
                target={"_blank"}
                aria-label={"LinkedIn"}
                icon={<FaLinkedin fontSize={"1.5rem"} />}
              />
              <IconButton
                as={"a"}
                color={isMarketplace ? "darkBlue.500" : "white"}
                href={"https://instagram.com/virtuelles_interaktives_lernen"}
                target={"_blank"}
                aria-label={"Instagram"}
                icon={<FaInstagram fontSize={"1.5rem"} />}
              />
              <Button
                onClick={handleLoginWithBildungsmediathekNRW}
                variant="outline"
                colorScheme={"white"}
                _hover={{ bgColor: "darkBlue.600" }}
                size={{ base: "sm", md: "md", lg: "md" }}
                leftIcon={<ExternalLinkIcon className="w-5 h-5" />}
              >
                BiMe
              </Button>
            </ButtonGroup>
          </Flex>
          <Center>
            <Text fontSize={"sm"} color={"subtle"}>
              &copy; {new Date().getFullYear()} VIL GmbH. All rights reserved.
            </Text>
          </Center>
        </Flex>
      </Box>
    </Box>
  );
}
